import { ChatMessage, MESSAGE_AUTHOR } from '@/types/models';
import { Review } from '@prisma/client';
import { UseInfiniteQueryOptions } from '@tanstack/react-query';
import ReloadTokens, { ReloadTokenType, useReloadTokensState } from '../state/ReloadTokensState';
import { genericAuthRequest, genericRequest, useMailberryMutate, useMailberryQuery } from './base';

export function useGetEmailReview(draftId: string) {
  const { reloadTokens } = ReloadTokens.useContainer();
  return useMailberryQuery<Review & { businessAddress: string; }>({
    f: () => {
      return genericRequest('get', `/review`, { draftId });
    },
    queryKey: [reloadTokens[ReloadTokenType.Comments], draftId],
    options: { cacheTime: 0 },
  });
}

// export function useCreateEmailReview(onSuccess?: () => void, onError?: () => void) {
//   return useMailberryMutate<EmailReview, any>({
//     f: (accessToken, userData) => {
//       return genericRequest('post', `/review`, userData);
//     },
//     options: { onSuccess, onError },
//   });
// }

export function useUpdateEmailReview() {
  return useMailberryMutate<Review, {}>({
    f: (accessToken, userData) => {
      return genericRequest('put', `/review`, userData);
    },
    reloadTokenType: ReloadTokenType.Comments,
  });
}

export function useSendTestEmail(onSuccess?: () => void, onError?: () => void) {
  return useMailberryMutate<any, { draftId: string; email: string; }>({
    f: (accessToken, userData) => {
      return genericRequest('post', `/review/sendTest`, userData);
    },
  });
}

export function useGetAllLastReview() {
  return useMailberryQuery<(Review & { scheduledTo: string; createdAt: string; updatedAt: string; })[]>({
    f: accessToken => {
      return genericAuthRequest(accessToken, 'get', `/review/all-last`);
    },
    options: { refetchInterval: 10000, cacheTime: 0 },
  });
}
