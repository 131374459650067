import { useGetAccount } from '@/services/api/account';
import React from 'react';
import DeliveriesList from './Overview/DeliveriesList';

import SectionTitle from '@/components/common/SectionTitle';
import AccountInfo from './AccountInfo';
import DownloadAppBanner from './DownloadAppBanner';
import OverallStats from './OverallStats';

export default function Dashboard() {
  const { data: account, isLoading: isLoadingUser } = useGetAccount();
  return (
    <>
      <AccountInfo account={account} isLoading={isLoadingUser} />
      <OverallStats account={account} isLoading={isLoadingUser} />
      <SectionTitle title='Campaigns' href='/reports' />
      <DeliveriesList />
      <DownloadAppBanner isAuthenticated />
    </>
  );
}
