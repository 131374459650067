import ChevronRight from '@/assets/icons/ChevronRight';
import { Anchor, Title } from '@mantine/core';
import React from 'react';

interface SectionTitleProps {
  title: string;
  href?: string;
}

export default function SectionTitle({ title, href }: SectionTitleProps) {
  const content = (
    <Title order={4} fw={600} style={{ display: 'flex', alignItems: 'center', gap: '4px', color: '#000', marginLeft: '20px' }}>
      {title}
      {href && <ChevronRight size={20} />}
    </Title>
  );

  if (href) {
    return (
      <Anchor href={href} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        {content}
      </Anchor>
    );
  }

  return content;
}
