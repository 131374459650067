import { displayErrorNotification } from '@/components/display/notifications';
import { postVerify } from '@/services/api/auth';
import { stopNotifications } from '@/services/pushNotifications';
import PersistentStorage from '@/services/storage';
import { Capacitor } from '@capacitor/core';
import { useTour } from '@reactour/tour';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { stopDemoNotifications } from '../pushNotifications/demo';
import AppState from '../state/AppState';

export function useAuth() {
  const navigate = useNavigate();
  const appState = AppState.useContainer();
  const authMutation = useMutation({ mutationFn: postVerify });
  const { setCurrentStep, setIsOpen } = useTour();
  const location = useLocation();

  const currentPath = location.pathname + location.search;

  const resetTour = () => {
    setCurrentStep(0);
    setIsOpen(false);
  };

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function signIn(email: string, code: string, onSignInSuccess: () => void, impersonate: boolean = false) {
    try {
      let accessToken;
      appState.userAndCustomer.setIsLoading(true);
      // Bypass OTP login for development/testing
      if ((email === 'store@test.com' || process.env.NEXT_PUBLIC_NODE_ENV === 'development' || email === 'nike+staging@test.com') && !impersonate) {
        accessToken = code;
      } else {
        const { data } = await authMutation.mutateAsync({ email, code });
        const { error, message } = data?.data;
        accessToken = data?.data?.accessToken;

        if (error) {
          displayErrorNotification('Error', message, 5000);
        }
      }

      if (!accessToken) return;

      appState.signupAndLogin.setAccessToken(accessToken);
      appState.signupAndLogin.setEmail(email);

      PersistentStorage.saveItem({
        email,
        accessToken: accessToken,
        refreshToken: '',
        updatedAt: new Date().toUTCString(),
        chat: {},
        isDemo: false,
      });

      onSignInSuccess();
    } catch (error: any) {
      console.error('SignIn Error:', error);
    } finally {
      appState.userAndCustomer.setIsLoading(false);
    }
  }

  async function signOut(onSignOutDone?: () => void) {
    appState.userAndCustomer.setIsLoading(true);
    const isDemo = appState.signupAndLogin.isDemo;

    try {
      localStorage.clear();
      await PersistentStorage.deleteAll();
      appState.resetAll();
      if (isDemo) {
        resetTour();
      }
    } catch (error) {
      console.error('Error during sign out:', error);
    } finally {
      appState.userAndCustomer.setIsLoading(false);
      if (Capacitor.isNativePlatform()) {
        if (isDemo) {
          stopDemoNotifications(appState.signupAndLogin.accessToken);
        } else {
          stopNotifications(appState.signupAndLogin.accessToken);
        }
      }

      if (onSignOutDone) {
        onSignOutDone();
      }
      navigate('/login', { state: { redirectTo: currentPath } });
    }
  }

  async function setAccessToken(accessToken: string, email: string = '', isDemo: boolean = false) {
    appState.userAndCustomer.setIsLoading(true);
    appState.signupAndLogin.setAccessToken(accessToken);
    appState.signupAndLogin.setIsDemo(isDemo);
    await PersistentStorage.saveItem({
      email,
      accessToken: accessToken,
      refreshToken: '',
      updatedAt: new Date().toUTCString(),
      chat: {},
      isDemo,
    });
    appState.userAndCustomer.setIsLoading(false);
  }

  return {
    clearErrorMessage: () => setErrorMessage(null),
    errorMessage,
    isLoading: appState.userAndCustomer.isLoading,
    isAuthenticated: appState.signupAndLogin.isAuthenticated,
    accessToken: appState.signupAndLogin.accessToken,
    isDemo: appState.signupAndLogin.isDemo,
    signIn,
    signOut,
    setAccessToken,
  };
}
