import Layout from '@/components/Layout';
import ProtectedDemoRoute from '@/components/ProtectedDemoRoute';
import ProtectedRoute from '@/components/ProtectedRoute';
import AdminChat from '@/components/screens/AdminChat';
import SignIn from '@/components/screens/Auth/SignIn';
import SignUpDemo from '@/components/screens/Auth/SignUpDemo';
import DemoChat from '@/components/screens/Chat/DemoChat';
import Dashboard from '@/components/screens/Dashboard';

import EmailReview from '@/components/screens/EmailReview';
import { DemoEmailReview } from '@/components/screens/EmailReview/DemoEmailReview';
import ReportStats from '@/components/screens/EmailStats';
import DemoReportStats from '@/components/screens/EmailStats/DemoReportStats';
import ShopifyIntegration from '@/components/screens/Settings/Shopify';
import EnableShopifyApp from '@/components/screens/Settings/Shopify/EnableShopifyApp';
import InstallShopifyApp from '@/components/screens/Settings/Shopify/InstallShopifyApp';
import InstallZapierIntegration from '@/components/screens/Settings/Zapier/InstallZapierIntegration';

import CapacitorWrapper from '@/app/CapacitorWrapper';
import DemoDashboard from '@/components/screens/Dashboard/Demo/DemoDashboard';
import DemoReports from '@/components/screens/Dashboard/Demo/DemoReportList';
import DeliveriesList from '@/components/screens/Dashboard/Overview/DeliveriesList';
import PersistentStorage from '@/services/storage';
import DemoLayout from '@components/DemoLayout';
import Assets from '@components/screens/Assets';
import Chat from '@components/screens/Chat';
import Settings from '@components/screens/Settings';
import { Transition } from '@mantine/core';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// right way of logging things on Mobile device iOS Android
let log = console.log.bind(console);

const Main = () => {
  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'calc(100dvh - (env(safe-area-inset-top) + env(safe-area-inset-bottom)) )',
        marginTop: 'env(safe-area-inset-top)',
        marginBottom: 'env(safe-area-inset-bottom)',
        backgroundColor: 'white',
      }}
    >
      {/*<CapacitorWrapper></CapacitorWrapper>*/}
      <RouterProvider
        router={createBrowserRouter([
          {
            element: <CapacitorWrapper />,
            children: [
              {
                path: '/login',
                element: <SignIn />,
              },
              {
                path: '/signup-demo',
                element: <SignUpDemo />,
              },
              {
                path: '/review/:draftId',
                element: (
                  <Layout>
                    <EmailReview standalonePage />
                  </Layout>
                ),
              },
              {
                path: '/admin-chat',
                element: (
                  <Layout scroll={false}>
                    <AdminChat />
                  </Layout>
                ),
              },

              //  PROTECTED ROUTES
              {
                path: '/',
                element: (
                  <ProtectedRoute>
                    <Layout>
                      <Dashboard />
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/chat',
                element: (
                  <ProtectedRoute>
                    <Layout scroll={false}>
                      <Transition mounted={true} transition='pop-bottom-right' duration={200}>
                        {styles => <Chat styles={styles} />}
                      </Transition>
                    </Layout>
                  </ProtectedRoute>
                ),
                // Route actions are the "writes" to route loader "reads".
                // Actions are called whenever the app sends a non-get submission ("post", "put", "patch", "delete") to your route.
                action: ({ params }) => {
                  return { params: 'a' };
                },
                loader: async ({ params }) => {
                  const messages = await PersistentStorage.getMessagesLocal();
                  return messages[0]?.id || null;
                },
              },
              {
                path: '/reports',
                element: (
                  <ProtectedRoute>
                    <Layout>
                      <DeliveriesList withPagination={true} />;
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/reports/:draftId',
                element: (
                  <ProtectedRoute>
                    <Layout>
                      <ReportStats showHeader={false} />
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/settings',
                element: (
                  <ProtectedRoute>
                    <Layout>
                      <Transition mounted={true} transition='slide-left' duration={2000}>
                        {styles => <Settings styles={styles} />}
                      </Transition>
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/settings/assets',
                element: (
                  <ProtectedRoute>
                    <Layout scroll={false}>
                      <Assets />
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/settings/shopify',
                element: (
                  <ProtectedRoute>
                    <Layout scroll={true}>
                      <ShopifyIntegration />
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/auth/shopify',
                element: (
                  <ProtectedRoute>
                    <Layout>
                      <InstallShopifyApp />
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/auth/shopify/callback',
                element: (
                  <ProtectedRoute>
                    <Layout>
                      <EnableShopifyApp />
                    </Layout>
                  </ProtectedRoute>
                ),
              },
              {
                path: '/auth/zapier',
                element: (
                  <ProtectedRoute>
                    <Layout>
                      <InstallZapierIntegration />
                    </Layout>
                  </ProtectedRoute>
                ),
              },

              // DEMO ROUTES
              {
                path: '/demo',
                element: (
                  <ProtectedDemoRoute>
                    <DemoLayout>
                      <DemoDashboard />
                    </DemoLayout>
                  </ProtectedDemoRoute>
                ),
              },
              {
                path: '/demo/chat',
                element: (
                  <ProtectedDemoRoute>
                    <DemoLayout>
                      <Transition mounted={true} transition='pop-bottom-right' duration={200}>
                        {styles => <DemoChat styles={styles} />}
                      </Transition>
                    </DemoLayout>
                  </ProtectedDemoRoute>
                ),
              },
              {
                path: '/demo/review/:draftId',
                element: (
                  <ProtectedDemoRoute>
                    <DemoLayout>
                      <DemoEmailReview standalonePage />
                    </DemoLayout>
                  </ProtectedDemoRoute>
                ),
              },
              {
                path: '/demo/reports',
                element: (
                  <ProtectedDemoRoute>
                    <DemoLayout>
                      <DemoReports withPagination={true} />
                    </DemoLayout>
                  </ProtectedDemoRoute>
                ),
              },
              {
                path: '/demo/reports/:draftId',
                element: (
                  <ProtectedDemoRoute>
                    <DemoLayout>
                      <DemoReportStats />
                    </DemoLayout>
                  </ProtectedDemoRoute>
                ),
              },
              {
                path: '/demo/settings',
                element: (
                  <ProtectedDemoRoute>
                    <DemoLayout>
                      <Transition mounted={true} transition='slide-left' duration={2000}>
                        {styles => <Settings styles={styles} />}
                      </Transition>
                    </DemoLayout>
                  </ProtectedDemoRoute>
                ),
              },
            ],
          },
        ])}
      />
    </main>
  );
};

export default Main;
